import React, { useState,useEffect } from 'react';
const axios = require('axios');


function App() {
  const [urls, setUrls] = useState({titles:[],alphas:{}});

  useEffect(() => {
    axios.get('/titles.json')
    .then(function (response) {
      // handle success
      console.log(response)
      setUrls(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }, []);

  return (
    <div className="App">
      <h2>The Movies</h2>
      <p>({urls.titles.length || 7075} Spoilers in database)</p>
      <select onChange={function(e){window.location = e.target.value;}}>
      {
        Object.keys(urls.alphas).map(alpha => {
          var val = `#${alpha}`;
          return (
            <option value={val}>{alpha}</option>
          )})
      }
      </select>
      {
        Object.keys(urls.alphas).map(alpha => {
          return (
            <div>
              <h2 id={alpha}>{alpha}</h2>
              {urls.alphas[alpha].map(title =>{
                return (
                  <div>
                    <a href={title.url}>{title.post_title}</a>
                  </div>
                )
              })}
            </div>
          )
        })
      }
    </div>
  );
}

export default App;
